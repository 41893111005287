body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.definition {
  text-align: justify;
}

.definition {
  margin-bottom: 1em;
  line-height: 1.5em;
}

nav {
  margin-bottom: 1em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.separator::after {
  content: ' \2022   ';
  color: lightgray;
}

.BigSearch form {
  size: 2em !important;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.loading {
  opacity: 0;
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.loading::after {
  content: "Loading...";
}

.BigSearch h1, .BigSearch h2 {
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.5em;
}

.BigSearch h2 {
  margin-bottom: 1em;
  font-size: 1em;
}

.BigSearch .elof {
  width: 10em;
  height: 10em;
  margin-top: 1em;
  margin-bottom: 1em;
  opacity: 0.15;
  display: block;
  -webkit-transition: opacity ease-in-out 0.25s;
  transition: opacity ease-in-out 0.25s;
}

.BigSearch .elof:hover {
  opacity: 1;
}
